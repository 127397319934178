import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "../core/layouts/AuthLayout";
import SidebarLayout from "../core/layouts/SidebarLayout";
import AuthGuard from "../core/layouts/AuthGuard";
import { RouteItem } from "../core/interfaces/routes.interface";
import { authRoutes } from "../core/services/routes/auth-routes";
import { adminRoutes } from "../core/services/routes/admin-routes";
import { userRoutes } from "../core/services/routes/user-routes";
import { productRoutes } from "../core/services/routes/product-routes";
import ProductLayout from "../core/layouts/ProductLayout";
import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export type ChildRoutesProps = {
    Layout: any;
    routes: RouteItem[];
};

const childRoutes: React.FC<ChildRoutesProps> = ({ Layout, routes }): any => {
    return routes.map(
        (
            {
                component: Component,
                guard,
                path,
                index,
                roles,
                userTypes,
                props,
                subItems,
            },
            key
        ) => {
            const Guard = guard;

            if (subItems) {
                return subItems.flatMap((subItem, subKey) => {
                    if (subItem.component)
                        return (
                            <Route
                                index={subItem.index}
                                key={subKey}
                                path={subItem.path}
                                element={
                                    <Layout>
                                        {guard ? (
                                            <Guard
                                                roles={roles}
                                                userTypes={userTypes}
                                                path={path}
                                            >
                                                <Suspense>
                                                    <subItem.component {...props} />
                                                </Suspense>
                                            </Guard>
                                        ) : (
                                            <subItem.component {...props} />
                                        )}
                                    </Layout>
                                }
                            />
                        );
                });
            }
            if (Component)
                return (
                    <Route
                        index={index}
                        key={key}
                        path={path}
                        element={
                            <Layout>
                                {guard ? (
                                    <Guard
                                        roles={roles}
                                        userTypes={userTypes}
                                        path={path}
                                    >
                                        <Suspense>
                                            <Component {...props} />
                                        </Suspense>
                                    </Guard>
                                ) : (
                                    <Suspense>
                                        <Component {...props} />
                                    </Suspense>
                                )}
                            </Layout>
                        }
                    />
                );
        }
    );
};

const DispensaryRoutes = () => {
    return (
        <SentryRoutes>
            {childRoutes({
                routes: authRoutes,
                Layout: AuthLayout,
            })}

            {childRoutes({
                routes: productRoutes,
                Layout: ProductLayout,
            })}

            {childRoutes({
                routes: userRoutes,
                Layout: SidebarLayout,
            })}
            {childRoutes({
                routes: adminRoutes,
                Layout: SidebarLayout,
            })}
            <Route path="/" element={<AuthGuard />} />
            <Route path="*" element={<AuthLayout />} />
        </SentryRoutes>
    );
};

export default DispensaryRoutes;
