import { ThreeDots } from "react-loader-spinner";

interface Props {
    height?: number;
    width?: number;
    color?: string;
}

const Loader: React.FC<Props> = ({color = '#3266DC', height, width}) => {
    return <ThreeDots color={color} height={height} width={width} />;
};

export default Loader;
