import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getStores as fetchStores,
    getStore as fetchStore,
} from "../../services/store/store.service";
import {
    getStoresSuccess,
    getStoresFailure,
    getStores,
    getStoreSuccess,
    getStoreFailure,
    getStore,
} from "../reducer/stores";
import { GetStoreActionPayload, GetStoresPayload } from "../types/stores";

function* getStoresSaga(actions: GetStoresPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchStores,
            actions.payload
        );
        yield put(getStoresSuccess(data));
    } catch (error: any) {
        yield put(getStoresFailure(error));
    }
}

function* getStoreSaga(actions: GetStoreActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(fetchStore, actions.payload);
        yield put(getStoreSuccess(data));
    } catch (error: any) {
        yield put(getStoreFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getStores.type, getStoresSaga);
    yield takeLatest(getStore.type, getStoreSaga);
}
