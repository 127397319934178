import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { INavigation } from "../../interfaces/navigation.interface";
import { useState } from "react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

type Props = {
    path: string;
    name: string;
    icon: any;
    iconActive?: any;
    subItems: INavigation[];
    isExpanded: boolean;
};

const SideBarItem: React.FC<Props> = ({
    path,
    name,
    subItems,
    isExpanded,
    ...props
}) => {
    const location = useLocation();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const isCurrent = location?.pathname?.includes(path) || false;
    const rootClass = classNames(
        "menu-horizontal w-full py-3 my-1 text-sm text-white",
        {
            "bg-primary text-white font-bold rounded-lg": isCurrent,
            "pl-4": isExpanded,
            "justify-center !p-3": !isExpanded,
        }
    );
    const iconClass = classNames("flex-shrink-0 w-5 h-5 text-white", {
        "text-white": isCurrent,
        "mr-3": isExpanded,
    });

    return (
        <li>
            {subItems ? (
                <div className="flex flex-col p-0">
                    <Link
                        to="#"
                        className={`${rootClass} ${
                            subItems ? "hover:bg-transparent" : ""
                        }`}
                        onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
                    >
                        <props.icon className={iconClass} aria-hidden="true" />
                        {isExpanded && name}
                        {isExpanded &&
                            (isSubmenuOpen ? (
                                <ChevronDownIcon
                                    className="ml-auto mr-2 w-4 h-4"
                                    aria-hidden="true"
                                />
                            ) : (
                                <ChevronRightIcon
                                    className="ml-auto mr-2 h-4 w-4"
                                    aria-hidden="true"
                                />
                            ))}
                    </Link>
                    {isExpanded && (
                        <ul
                            className={
                                isSubmenuOpen ? "block w-full pl-5" : "hidden"
                            }
                        >
                            {subItems.map((item, idx) => (
                                <SideBarItem
                                    icon={item.icon as any}
                                    name={item?.name}
                                    iconActive={item.iconActive}
                                    path={item?.path}
                                    subItems={item.subItems}
                                    key={idx}
                                    isExpanded={isExpanded}
                                />
                            ))}
                        </ul>
                    )}
                </div>
            ) : (
                <Link to={path} className={rootClass}>
                    {isCurrent ? (
                        props.iconActive ? (
                            <props.iconActive
                                className={iconClass}
                                aria-hidden="true"
                            />
                        ) : (
                            <props.icon
                                className={iconClass}
                                aria-hidden="true"
                            />
                        )
                    ) : (
                        <props.icon className={iconClass} aria-hidden="true" />
                    )}
                    {isExpanded && name}
                </Link>
            )}
        </li>
    );
};

export default SideBarItem;
