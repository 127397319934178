import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import {
    AdminOrganizationsState,
    GetAdminOrganizationsActionPayload,
    GetAdminOrganizationActionPayload,
} from "../types/admin-organization";

const initialState: AdminOrganizationsState = {
    getAdminOrganizations: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getAdminOrganization: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const adminOrganizationSlice = createSlice({
    name: "adminOrganization",
    initialState,
    reducers: {
        getAdminOrganizations(
            state,
            action: GetAdminOrganizationsActionPayload
        ) {
            state.getAdminOrganizations = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getAdminOrganizationsSuccess(state, actions) {
            state.getAdminOrganizations = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getAdminOrganizationsFailure(state) {
            state.getAdminOrganizations = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        /**
         *
         *ADMIN ORGANIZATION (SHOW BY ID)
         */
        getAdminOrganization(state, action: GetAdminOrganizationActionPayload) {
            state.getAdminOrganization = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getAdminOrganizationSuccess(state, actions) {
            state.getAdminOrganization = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getAdminOrganizationFailure(state) {
            state.getAdminOrganization = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
    },
});

export const {
    getAdminOrganizations,
    getAdminOrganizationsSuccess,
    getAdminOrganizationsFailure,
    getAdminOrganization,
    getAdminOrganizationSuccess,
    getAdminOrganizationFailure,
} = adminOrganizationSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        { getAdminOrganizations, getAdminOrganization },
        useDispatch()
    );
};

export default adminOrganizationSlice.reducer;
