import httpClient from "../../clients/httpClient";
import { IOrganizationAchievementPayload, IOrganizationAchievementsPayload } from "../../interfaces/organization-achievement.interface";

export const getOrganizationAchievements = ({ ...payload }: IOrganizationAchievementsPayload) => {
    const { page, perPage } = payload;
    const endpoint = page && perPage ? `/api/v1/organization-achievements?page=${page}&per_page=${perPage}` : `/api/v1/organization-achievements`;
    return httpClient.get(endpoint);
};

export const getOrganizationAchievement = (id: string) => {
    return httpClient.get(`/api/v1/organization-achievements/${id}`);
};

export const createOrganizationAchievement = (payload: IOrganizationAchievementPayload) => {
    return httpClient.post("/api/v1/organization-achievements", payload);
};

export const editOrganizationAchievement = (id: number, payload: IOrganizationAchievementPayload) => {
    return httpClient.post(`/api/v1/organization-achievements/${id}`, payload);
};

export const deleteOrganizationAchievement = (id: number) => {
    return httpClient.delete(`/api/v1/organization-achievements/${id}`);
};
