import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";


Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,

		}),
		Sentry.replayIntegration(),
		Sentry.contextLinesIntegration(),
		Sentry.browserTracingIntegration(),
		Sentry.httpClientIntegration(),
		Sentry.httpContextIntegration()
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// Learn more at
	// https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
	tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	//   tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	// Learn more at
	// https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	maxBreadcrumbs: 50,
	debug: true,
	attachStacktrace: true,
	environment: import.meta.env.VITE_ENVIRONMENT,
});


