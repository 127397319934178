import httpClient from "../../clients/httpClient";
import { IRolePayload } from "../../interfaces/role.interface";

export const getRolesByType = (type: string) => {
    return httpClient.get(`/api/v1/admin/roles/${type}`);
};

export const getOrganizationRolesByType = (type: string) => {
    return httpClient.get(`/api/v1/roles/${type}`);
};

export const createRole = (payload: IRolePayload) => {
    return httpClient.post("/api/v1/admin/roles", payload);
};

export const editRole = (id: number, payload: IRolePayload) => {
    return httpClient.patch(`/api/v1/admin/roles/${id}`, payload);
};

export const deleteRole = (id: number) => {
    return httpClient.delete(`/api/v1/admin/roles/${id}`);
};
