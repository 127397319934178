import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { PunchcardsState } from "../types/punchcards";

const initialState: PunchcardsState = {
    getPunchcard: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getPunchcards: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
};

const punchcardsSlice = createSlice({
    name: "punchcards",
    initialState,
    reducers: {
        getPunchcards(state, actions) {
            state.getPunchcards = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };

            state.getPunchcard = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        getPunchcardsSuccess(state, actions) {
            state.getPunchcards = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getPunchcardsFailure(state) {
            state.getPunchcards = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },

        getPunchcard(state) {
            state.getPunchcard = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getPunchcardSuccess(state, actions) {
            state.getPunchcard = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getPunchcardFailure(state) {
            state.getPunchcard = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetPunchcard(state) {
            state.getPunchcard = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        removePunchcardImageUrl(state) {
            if (state.getPunchcard?.data)
                state.getPunchcard.data.attributes.image = "";
        },
    },
});

export const {
    getPunchcard,
    getPunchcardFailure,
    getPunchcardSuccess,
    getPunchcards,
    getPunchcardsFailure,
    getPunchcardsSuccess,
    resetGetPunchcard,
    removePunchcardImageUrl,
} = punchcardsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getPunchcards,
            getPunchcard,
            resetGetPunchcard,
            removePunchcardImageUrl,
        },
        useDispatch()
    );
};

export default punchcardsSlice.reducer;
