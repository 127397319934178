import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { IntegrationsState } from "../types/integrations";

const initialState: IntegrationsState = {
    getIntegration: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getIntegrations: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getIntegrationTemplates: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const integrationsSlice = createSlice({
    name: "integrations",
    initialState,
    reducers: {
        getIntegrations(state) {
            state.getIntegrations = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getIntegrationsSuccess(state, actions) {
            state.getIntegrations = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getIntegrationsFailure(state) {
            state.getIntegrations = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        getIntegration(state) {
            state.getIntegration = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getIntegrationSuccess(state, actions) {
            state.getIntegration = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getIntegrationFailure(state) {
            state.getIntegration = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        getIntegrationTemplates(state) {
            state.getIntegrationTemplates = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getIntegrationTemplatesSuccess(state, actions) {
            state.getIntegrationTemplates = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getIntegrationTemplatesFailure(state) {
            state.getIntegrationTemplates = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        resetGetIntegration(state) {
            state.getIntegration = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
    },
});

export const {
    getIntegration,
    getIntegrationFailure,
    getIntegrationSuccess,
    getIntegrations,
    getIntegrationsFailure,
    getIntegrationsSuccess,
    getIntegrationTemplates,
    getIntegrationTemplatesFailure,
    getIntegrationTemplatesSuccess,
    resetGetIntegration,
} = integrationsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getIntegrations,
            getIntegration,
            getIntegrationTemplates,
            resetGetIntegration,
        },
        useDispatch()
    );
};

export default integrationsSlice.reducer;
