import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { AchievementState } from "../types/achievements";

const initialState: AchievementState = {
    getAchievements: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getAchievement: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const achievementsSlice = createSlice({
    name: "achievements",
    initialState,
    reducers: {
        getAchievements(state) {
            state.getAchievements = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getAchievementsSuccess(state, actions) {
            state.getAchievements = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getAchievementsFailure(state) {
            state.getAchievements = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getAchievement(state) {
            state.getAchievement = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getAchievementSuccess(state, actions) {
            state.getAchievement = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getAchievementFailure(state) {
            state.getAchievement = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetAchievement(state) {
            state.getAchievement = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
    },
});

export const {
    getAchievement,
    getAchievementSuccess,
    getAchievementFailure,
    getAchievements,
    getAchievementsFailure,
    getAchievementsSuccess,
    resetGetAchievement,
} = achievementsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        { getAchievement, getAchievements, resetGetAchievement },
        useDispatch()
    );
};

export default achievementsSlice.reducer;
