import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { RolesState } from "../types/roles";

const initialState: RolesState = {
    getRoles: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const rolesSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        getRoles(state) {
            state.getRoles = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getRolesSuccess(state, actions) {
            state.getRoles = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getRolesFailure(state) {
            state.getRoles = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
    },
});

export const { getRoles, getRolesSuccess, getRolesFailure } =
    rolesSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators({ getRoles }, useDispatch());
};

export default rolesSlice.reducer;
