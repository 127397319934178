import httpClient from "../../clients/httpClient";
import { IPermissionPayload } from "../../interfaces/permission.interface";

export const getPermissionsByType = (type: string) => {
    return httpClient.get(`/api/v1/admin/permissions/${type}`);
};

export const createPermission = (payload: IPermissionPayload) => {
    return httpClient.post("/api/v1/admin/permissions", payload);
};

export const editPermission = (id: number, payload: IPermissionPayload) => {
    return httpClient.patch(`/api/v1/admin/permissions/${id}`, payload);
};

export const deletePermission = (id: number) => {
    return httpClient.delete(`/api/v1/admin/permissions/${id}`);
};
