import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { getSettings as fetchSettings } from "../../services/setting/setting.service";
import {
    getSettings,
    getSettingsFailure,
    getSettingsSuccess,
} from "../reducer/settings";

function* getSettingsSaga() {
    try {
        const { data }: AxiosResponse = yield call(fetchSettings);

        yield put(getSettingsSuccess(data));
    } catch (error: any) {
        yield put(getSettingsFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getSettings.type, getSettingsSaga);
}
