import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { AdminUsersState, GetAdminUsersActionPayload } from "../types/admin-users";

const initialState: AdminUsersState = {
    getAdminUsers: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getAdminUser: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const adminUsersSlice = createSlice({
    name: "adminUsers",
    initialState,
    reducers: {
        getAdminUsers(state, action: GetAdminUsersActionPayload) {
            state.getAdminUsers = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getAdminUsersSuccess(state, actions) {
            state.getAdminUsers = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getAdminUsersFailure(state) {
            state.getAdminUsers = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        /**
         *
         */
        getAdminUser(state) {
            state.getAdminUser = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getAdminUserSuccess(state, actions) {
            state.getAdminUser = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getAdminUserFailure(state) {
            state.getAdminUser = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
    },
});

export const {
    getAdminUsers,
    getAdminUsersSuccess,
    getAdminUsersFailure,
    getAdminUser,
    getAdminUserSuccess,
    getAdminUserFailure,
} = adminUsersSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators({ getAdminUsers, getAdminUser }, useDispatch());
};

export default adminUsersSlice.reducer;
