import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getAchievement as fetchAchievement,
    getAchievements as fetchAchievements,
} from "../../services/achievement/achievement.service";
import {
    getAchievements,
    getAchievementsSuccess,
    getAchievementsFailure,
    getAchievement,
    getAchievementSuccess,
    getAchievementFailure,
} from "../reducer/achievements";
import { GetAchievementActionPayload } from "../types/achievements";

function* getAchievementsSaga() {
    try {
        const { data }: AxiosResponse = yield call(fetchAchievements);
        yield put(getAchievementsSuccess(data));
    } catch (error: any) {
        yield put(getAchievementsFailure(error));
    }
}

function* getAchievementSaga(actions: GetAchievementActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchAchievement,
            actions.payload
        );
        yield put(getAchievementSuccess(data));
    } catch (error: any) {
        yield put(getAchievementFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getAchievements.type, getAchievementsSaga);
    yield takeLatest(getAchievement.type, getAchievementSaga);
}
