import httpClient from "../../clients/httpClient";
import { IQuery } from "../../interfaces/meta.interface";
import { IPunchcard, IPunchcardPayload, IPunchcardQuery, IPunchcardRedemption, IPunchcardRedemptionResponse } from "../../interfaces/punchcard.interface";

export const getPunchcards = (query: IQuery) => {
    return httpClient.get("/api/v1/punchcards", {
        params: {
            page: query?.page,
            per_page: query?.perPage,
        },
    });
};

export const getPunchcard = (id: number) => {
    return httpClient.get(`/api/v1/punchcards/${id}`);
};

export const createPunchcard = (payload: FormData) => {
    return httpClient.post<IPunchcard>("/api/v1/punchcards", payload);
};

export const editPunchcard = (id: number, payload: FormData) => {
    return httpClient.post<IPunchcard>(`/api/v1/punchcards/${id}`, payload);
};

export const deletePunchcard = (id: number) => {
    return httpClient.delete(`/api/v1/punchcards/${id}`);
};


export const getPunchcardRedemptions = (organizationId: number, userId: number, query: IPunchcardQuery) => {
    return httpClient.get<IPunchcardRedemptionResponse>(`/api/v1/organizations/${organizationId}/customer/${userId}/punchcard-redemptions`, {
        params: {
            page: query?.page,
            per_page: query?.perPage,
            status: query?.status,
        },
    });
}

export const approvePunchcardRedemption = (organizationId: number, userId: number, punchcardId: number) => {
    return httpClient.post<IPunchcardRedemption>(`/api/v1/organizations/${organizationId}/customer/${userId}/punchcard-redemptions/${punchcardId}/approve`);
}