import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { OrganizationAchievementState } from "../types/organization-achievements";

const initialState: OrganizationAchievementState = {
    getOrganizationAchievements: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
    getOrganizationAchievement: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const organizationAchievementsSlice = createSlice({
    name: "organizationAchievements",
    initialState,
    reducers: {
        getOrganizationAchievements(state, actions) {
            state.getOrganizationAchievements = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };
        },
        getOrganizationAchievementsSuccess(state, actions) {
            state.getOrganizationAchievements = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getOrganizationAchievementsFailure(state) {
            state.getOrganizationAchievements = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getOrganizationAchievement(state) {
            state.getOrganizationAchievement = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getOrganizationAchievementSuccess(state, actions) {
            state.getOrganizationAchievement = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getOrganizationAchievementFailure(state) {
            state.getOrganizationAchievement = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetOrganizationAchievement(state) {
            state.getOrganizationAchievement = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        removeOrganizationAchievementImage(state) {
            if (state.getOrganizationAchievement.data?.attributes.image)
                state.getOrganizationAchievement.data.attributes.image = "";
        },
        removeOrganizationAchievementImageUnearned(state) {
            if (
                state.getOrganizationAchievement.data?.attributes.image_unearned
            )
                state.getOrganizationAchievement.data.attributes.image_unearned =
                    "";
        },
    },
});

export const {
    getOrganizationAchievement,
    getOrganizationAchievementSuccess,
    getOrganizationAchievementFailure,
    getOrganizationAchievements,
    getOrganizationAchievementsFailure,
    getOrganizationAchievementsSuccess,
    resetGetOrganizationAchievement,
    removeOrganizationAchievementImage,
    removeOrganizationAchievementImageUnearned,
} = organizationAchievementsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getOrganizationAchievement,
            getOrganizationAchievements,
            resetGetOrganizationAchievement,
            removeOrganizationAchievementImage,
            removeOrganizationAchievementImageUnearned,
        },
        useDispatch()
    );
};

export default organizationAchievementsSlice.reducer;
