import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { getUsers, getUser } from "../../services/user/user.service";
import {
    getAdminUsers,
    getAdminUsersFailure,
    getAdminUsersSuccess,
    getAdminUser,
    getAdminUserFailure,
    getAdminUserSuccess,
} from "../reducer/admin-users";
import { GetAdminUserActionPayload, GetAdminUsersActionPayload } from "../types/admin-users";

function* getAdminUsersSaga(actions: GetAdminUsersActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(getUsers, actions.payload);
        yield put(getAdminUsersSuccess(data));
    } catch (error: any) {
        yield put(getAdminUsersFailure(error));
    }
}

function* getAdminUserSaga(action: GetAdminUserActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(getUser, action.payload);
        yield put(getAdminUserSuccess(data));
    } catch (error: any) {
        yield put(getAdminUserFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getAdminUsers.type, getAdminUsersSaga);
    yield takeLatest(getAdminUser.type, getAdminUserSaga);
}
