import httpClient from "../../clients/httpClient";
import { IPromotion, IPromotionResponse } from "../../interfaces/promotion.interface";
import { IPromotionQuery } from "../../interfaces/store.interface";

export const getPromotions = (query: IPromotionQuery) => {
    return httpClient.get<IPromotionResponse>("/api/v1/promotions", {
        params: {
            page: query?.page,
            per_page: query?.perPage,
            search: query?.search,
            store_id: query?.store_id ? query?.store_id : undefined,
            order_by: query?.order_by,
            direction: query?.direction,
            status: query?.status,
        },
    });
};

export const getActivePromotions = (store: number) => {
    return httpClient.get<IPromotion[]>(`/api/v1/stores/${store}/promotions/active`);
};

export const getPromotion = (id: number) => {
    return httpClient.get<IPromotion>(`/api/v1/promotions/${id}`);
};

export const createPromotion = (payload: FormData) => {
    return httpClient.post<IPromotion>("/api/v1/promotions", payload);
};

export const editPromotion = (id: number, payload: FormData) => {
    return httpClient.post<IPromotion>(`/api/v1/promotions/${id}`, payload);
};

export const deletePromotion = (id: number) => {
    return httpClient.delete(`/api/v1/promotions/${id}`);
};
