import httpClient from "../../clients/httpClient";
import {IIntegration, IIntegrationPayload} from "../../interfaces/integration.interface";

export const getIntegrations = (service_type?: string) => {
    return httpClient.get<IIntegration[]>(`/api/v1/integrations`, {
        params: {
            service_type,
        },
    });
};

export const getIntegration = (id: string) => {
    return httpClient.get(`/api/v1/integrations/${id}`);
};

export const createIntegration = (payload: IIntegrationPayload) => {
    return httpClient.post("/api/v1/integrations", payload);
};

export const editIntegration = (id: number, payload: IIntegrationPayload) => {
    return httpClient.post(`/api/v1/integrations/${id}`, payload);
};

export const deleteIntegration = (id: number) => {
    return httpClient.delete(`/api/v1/integrations/${id}`);
};

export const getIntegrationTemplates = () => {
    return httpClient.get(`/api/v1/integration-templates`);
};
