import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { RoleTypes } from "../../enums/roles.enum";
import { getRolesByType } from "../../services/role/role.service";
import { getRoles, getRolesSuccess, getRolesFailure } from "../reducer/roles";

function* getOrganizationStaffSaga() {
    try {
        const { data: adminRoles }: AxiosResponse = yield call(
            getRolesByType,
            RoleTypes.admin
        );
        const { data: userRoles }: AxiosResponse = yield call(
            getRolesByType,
            RoleTypes.user
        );
        yield put(getRolesSuccess([...adminRoles, ...userRoles]));
    } catch (error: any) {
        yield put(getRolesFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getRoles.type, getOrganizationStaffSaga);
}
