import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getIntegrationTemplate as fetchIntegrationTemplate,
    getIntegrationTemplates as fetchIntegrationTemplates,
} from "../../services/integration-template/integration-template.service";
import {
    getIntegrationTemplates,
    getIntegrationTemplatesSuccess,
    getIntegrationTemplatesFailure,
    getIntegrationTemplate,
    getIntegrationTemplateSuccess,
    getIntegrationTemplateFailure,
} from "../reducer/integration-templates";
import { GetIntegrationTemplateActionPayload } from "../types/integration-templates";

function* getIntegrationTemplatesSaga() {
    try {
        const { data }: AxiosResponse = yield call(fetchIntegrationTemplates);
        yield put(getIntegrationTemplatesSuccess(data));
    } catch (error: any) {
        yield put(getIntegrationTemplatesFailure(error));
    }
}

function* getIntegrationTemplateSaga(actions: GetIntegrationTemplateActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchIntegrationTemplate,
            actions.payload
        );
        yield put(getIntegrationTemplateSuccess(data));
    } catch (error: any) {
        yield put(getIntegrationTemplateFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getIntegrationTemplates.type, getIntegrationTemplatesSaga);
    yield takeLatest(getIntegrationTemplate.type, getIntegrationTemplateSaga);
}
