import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { StoresState } from "../types/stores";

const initialState: StoresState = {
    getStores: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
    getStore: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const storesSlice = createSlice({
    name: "stores",
    initialState,
    reducers: {
        getStores(state, actions) {
            state.getStores = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };
        },
        getStoresSuccess(state, actions) {
            state.getStores = {
                data: Array.isArray(actions.payload) ? {
                    data: actions.payload,
                } : actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getStoresFailure(state) {
            state.getStores = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getStore(state) {
            state.getStore = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getStoreSuccess(state, actions) {
            state.getStore = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getStoreFailure(state) {
            state.getStore = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        resetGetStore(state) {
            state.getStore = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        removeStoreImage(state, actions) {
            if (state.getStore?.data?.relationships?.images)
                state.getStore.data.relationships.images.splice(
                    actions.payload,
                    1
                );
        },
        removeFeaturedImage(state) {
            if (state.getStore?.data?.attributes?.featured_image)
                state.getStore.data.attributes.featured_image = "";
        },
        editStoreSuccess(state, actions) {
            state.getStore = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
    },
});

export const {
    getStores,
    getStoresSuccess,
    getStoresFailure,
    getStore,
    getStoreSuccess,
    getStoreFailure,
    resetGetStore,
    removeStoreImage,
    removeFeaturedImage,
    editStoreSuccess,
} = storesSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getStores,
            getStore,
            resetGetStore,
            removeStoreImage,
            removeFeaturedImage,
            editStoreSuccess,
        },
        useDispatch()
    );
};

export default storesSlice.reducer;
