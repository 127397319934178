import httpClient from "../../clients/httpClient";
import { IProductReviewsPayload, IProductReviewPayload } from "../../interfaces/product-review.interface";

export const getProductReviews = ({ ...payload }: IProductReviewsPayload) => {
    const { page, perPage } = payload;
    return httpClient.get(`/api/v1/product-reviews?page=${page}&per_page=${perPage}`);
};

export const getProductReview = (id: number) => {
    return httpClient.get(`/api/v1/product-reviews/${id}`);
};

export const createProductReview = (payload: IProductReviewPayload) => {
    return httpClient.post("/api/v1/product-reviews", payload);
};

export const editProductReview = (id: number, payload: IProductReviewPayload) => {
    return httpClient.post(`/api/v1/product-reviews/${id}`, payload);
};

export const deleteProductReview = (id: number) => {
    return httpClient.delete(`/api/v1/product-reviews/${id}`);
};
