import httpClient from "../../clients/httpClient";
import { IOrganization } from "../../interfaces/organization.interface";

export const getOrganizationData = () => {
    return httpClient.get(`/api/v1/organizations`);
};

export const editOrganization = (payload: FormData) => {
    return httpClient.post<IOrganization>(`/api/v1/organizations`, payload, {
        params: {
            _method: "PATCH",
        }
    });
};
