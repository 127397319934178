import httpClient from "../../clients/httpClient";
import {IInviteStaffToOrganizationPayload} from "../../interfaces/organization.interface";

export const getStaff = (id?: string) => {
    if (id) {
        return httpClient.get(`/api/v1/organizations/${id}/staff`);
    }

    return httpClient.get(`/api/v1/organizations/staff`);
}

export const getInvitedStaff = () => {
    return httpClient.get(`/api/v1/invitations`);
}

export const removeStaff = (organization: number | undefined, staff: number) => {
    return httpClient.patch(`/api/v1/organizations/${organization}/staff/${staff}`);
}
export const organizationRemoveStaff = (staff: number) => {
    return httpClient.delete(`/api/v1/organizations/staff/${staff}`);
}

export const inviteStaff = (payload: IInviteStaffToOrganizationPayload) => {
    return httpClient.post('/api/v1/admin/invitations/', payload);
}

export const organizationInviteStaff = (payload: IInviteStaffToOrganizationPayload) => {
    return httpClient.post('/api/v1/invitations', payload);
}

export const organizationResendInviteStaff = (payload: IInviteStaffToOrganizationPayload) => {
    return httpClient.patch(`/api/v1/invitations/${[payload.id]}`, payload);
}

export const organizationCancelInvite = (id: number) => {
    return httpClient.delete(`/api/v1/invitations/${id}`);
}

export const cancelInvite = (id: number) => {
    return httpClient.delete(`/api/v1/admin/invitations/${id}`);
}
