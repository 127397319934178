import { combineReducers } from "@reduxjs/toolkit";
import adminOrganization from "./admin-organization";
import organizationStaff from "./organization-staff";
import permissions from "./permissions";
import adminUsers from "./admin-users";
import roles from "./roles";
import stores from "./stores";
import achievements from "./achievements";
import productReviews from "./product-reviews";
import promotions from "./promotions";
import settings from "./settings";
import organizationAchievements from "./organization-achievements";
import organization from "./organization";
import punchcards from "./punchcards";
import customers from "./customers";
import rewards from "./rewards";
import integrationTemplates from "./integration-templates";
import integrations from "./integrations";
import geofences from "./geofences";

const rootReducer = combineReducers({
    adminOrganization: adminOrganization,
    organizationStaff: organizationStaff,
    permissions: permissions,
    adminUsers: adminUsers,
    roles: roles,
    stores: stores,
    achievements: achievements,
    organizationAchievements: organizationAchievements,
    productReviews: productReviews,
    promotions: promotions,
    settings: settings,
    organization: organization,
    punchcards: punchcards,
    customers: customers,
    rewards: rewards,
    integrationTemplates: integrationTemplates,
    integrations: integrations,
    geofences: geofences,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
