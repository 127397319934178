import Skeleton from "react-loading-skeleton";
import { useThemeContext } from "../../context/theme";

interface Props {
    width?: number | string;
    height?: number | string;
    circle?: boolean;
    className?: string;
    count?: number;
}

const CustomSkeleton: React.FC<Props> = ({
    className,
    height,
    width,
    circle,
    count,
}) => {
    const { theme } = useThemeContext();

    return (
        <Skeleton
            width={width}
            height={height}
            count={count}
            className={className}
            circle={circle}
            baseColor={theme === "dark" ? "#1F2937" : undefined}
            highlightColor={theme === "dark" ? "#384556" : undefined}
        />
    );
};

export default CustomSkeleton;
