import { IPermission } from "../interfaces/permission.interface";

export const canUserAccess = (permission: string, permissions: IPermission[]) => {
    const userPermission = permission;

    const userHasPermission = permissions.some(
        (permission: IPermission) => {
            return permission.attributes.name === userPermission;
        }
    );

    return userHasPermission
};
