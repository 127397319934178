import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getGeofence as fetchGeofence,
    getGeofences as fetchGeofences,
} from "../../services/geofence/geofence.service";
import {
    getGeofences,
    getGeofencesSuccess,
    getGeofencesFailure,
    getGeofence,
    getGeofenceSuccess,
    getGeofenceFailure,
} from "../reducer/geofences";
import { GetGeofenceActionPayload } from "../types/geofences";

function* getGeofencesSaga() {
    try {
        const { data }: AxiosResponse = yield call(fetchGeofences);
        yield put(getGeofencesSuccess(data));
    } catch (error: any) {
        yield put(getGeofencesFailure(error));
    }
}

function* getGeofenceSaga(actions: GetGeofenceActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchGeofence,
            actions.payload
        );
        yield put(getGeofenceSuccess(data));
    } catch (error: any) {
        yield put(getGeofenceFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getGeofences.type, getGeofencesSaga);
    yield takeLatest(getGeofence.type, getGeofenceSaga);
}
