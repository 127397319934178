import { lazy } from "react";

import {
    Setting2,
    I3Dcube,
    Lock,
    Profile2User,
    Star1,
    ShoppingBag,
    Tag,
    Gift,
    NotificationStatus,
    TableDocument,
    Category2,
} from "iconsax-react";

import { RouteItem, UserTypes } from "../../interfaces/routes.interface";
import AuthGuard from "../../layouts/AuthGuard";
import { ROUTES } from "../../constants/routes";
import createIconWrapper from "../../components/shared/IconWrapper";

// Use React's lazy for code splitting
const AdminDashboard = lazy(() => import("../../../modules/admin/dashboard"));
const Organizations = lazy(
    () => import("../../../modules/admin/organizations")
);
const Organization = lazy(
    () => import("../../../modules/admin/organizations/Organization")
);
const OrganizationFeatures = lazy(
    () => import("../../../modules/admin/organizations/Features")
);
const Permissions = lazy(() => import("../../../modules/admin/permissions"));
const Roles = lazy(() => import("../../../modules/admin/roles"));
const Users = lazy(() => import("../../../modules/admin/users"));
const User = lazy(() => import("../../../modules/admin/users/User"));
const ProductSyncLogs = lazy(
    () => import("../../../modules/admin/sync-logs/ProductSyncLogs")
);
const PromotionSyncLogs = lazy(
    () => import("../../../modules/admin/sync-logs/PromotionSyncLogs")
);
const RewardSyncLogs = lazy(
    () => import("../../../modules/admin/sync-logs/RewardSyncLogs")
);
const NotificationLogs = lazy(
    () => import("../../../modules/admin/sync-logs/NotificationLogs")
);
const RegistrationAndVerificationLogs = lazy(
    () =>
        import(
            "../../../modules/admin/sync-logs/RegistrationAndVerificationLogs"
        )
);

const userTypes = [UserTypes.admin];
const roles = [] as any;

export const overview: RouteItem = {
    name: "Overview",
    id: ROUTES.ADMIN.dashboard.key,
    path: ROUTES.ADMIN.dashboard.key,
    component: AdminDashboard,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Category2, { variant: "Linear" }),
    iconActive: createIconWrapper(Category2, { variant: "Bold" }),
};

export const organizations: RouteItem = {
    name: "Organizations",
    id: ROUTES.ADMIN.organizations.key,
    path: ROUTES.ADMIN.organizations.key,
    component: Organizations,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(I3Dcube, { variant: "Linear" }),
    iconActive: createIconWrapper(I3Dcube, { variant: "Bold" }),
};

export const organizationsView: RouteItem = {
    name: "OrganizationsShow",
    id: ROUTES.ADMIN.organizations_view.key,
    path: ROUTES.ADMIN.organizations_view.key,
    component: Organization,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
};

export const organizationsFeature: RouteItem = {
    name: "OrganizationsFeature",
    id: ROUTES.ADMIN.organizations_features.key,
    path: ROUTES.ADMIN.organizations_features.key,
    component: OrganizationFeatures,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
};

export const permissions: RouteItem = {
    name: "Permissions",
    id: ROUTES.ADMIN.permissions.key,
    path: ROUTES.ADMIN.permissions.key,
    component: Permissions,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Lock, { variant: "Linear" }),
    iconActive: createIconWrapper(Lock, { variant: "Bold" }),
};

export const users: RouteItem = {
    name: "Users",
    id: ROUTES.ADMIN.users.key,
    path: ROUTES.ADMIN.users.key,
    component: Users,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Profile2User, { variant: "Linear" }),
    iconActive: createIconWrapper(Profile2User, { variant: "Bold" }),
};

export const usersView: RouteItem = {
    id: ROUTES.ADMIN.users_view.key,
    path: ROUTES.ADMIN.users_view.key,
    component: User,
    guard: AuthGuard,
    userTypes,
    roles,
    hidden: true,
};

export const rolesList: RouteItem = {
    name: "Roles",
    id: ROUTES.ADMIN.roles.key,
    path: ROUTES.ADMIN.roles.key,
    component: Roles,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Star1, { variant: "Linear" }),
    iconActive: createIconWrapper(Star1, { variant: "Bold" }),
};

export const settings: RouteItem = {
    name: "Settings",
    id: ROUTES.ADMIN.settings.key,
    path: ROUTES.ADMIN.settings.key,
    component: Roles,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Setting2, { variant: "Linear" }),
    iconActive: createIconWrapper(Setting2, { variant: "Bold" }),
    isSecondaryItem: true,
};

export const productSyncLogs: RouteItem = {
    name: "Product Syncs",
    id: ROUTES.ADMIN.product_sync_logs.key,
    path: ROUTES.ADMIN.product_sync_logs.key,
    component: ProductSyncLogs,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(ShoppingBag, { variant: "Linear" }),
    iconActive: createIconWrapper(ShoppingBag, { variant: "Bold" }),
};

export const promotionSyncLogs: RouteItem = {
    name: "Promotion Syncs",
    id: ROUTES.ADMIN.promotion_sync_logs.key,
    path: ROUTES.ADMIN.promotion_sync_logs.key,
    component: PromotionSyncLogs,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Tag, { variant: "Linear" }),
    iconActive: createIconWrapper(Tag, { variant: "Bold" }),
};

export const rewardSyncLogs: RouteItem = {
    name: "Reward Syncs",
    id: ROUTES.ADMIN.reward_sync_logs.key,
    path: ROUTES.ADMIN.reward_sync_logs.key,
    component: RewardSyncLogs,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Gift, { variant: "Linear" }),
    iconActive: createIconWrapper(Gift, { variant: "Bold" }),
};

export const notificationSentLogs: RouteItem = {
    name: "Notifications Sent",
    id: ROUTES.ADMIN.notification_logs.key,
    path: ROUTES.ADMIN.notification_logs.key,
    component: NotificationLogs,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(NotificationStatus, { variant: "Linear" }),
    iconActive: createIconWrapper(NotificationStatus, { variant: "Bold" }),
};

export const registrationAndVerificationLogs: RouteItem = {
    name: "Registrations & Verifications",
    id: ROUTES.ADMIN.registration_and_verifications_logs.key,
    path: ROUTES.ADMIN.registration_and_verifications_logs.key,
    component: RegistrationAndVerificationLogs,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(Profile2User, { variant: "Linear" }),
    iconActive: createIconWrapper(Profile2User, { variant: "Bold" }),
};

export const logs: RouteItem = {
    name: "Logs",
    id: ROUTES.ADMIN.logs.key,
    path: ROUTES.ADMIN.logs.key,
    guard: AuthGuard,
    userTypes,
    roles,
    icon: createIconWrapper(TableDocument, { variant: "Linear" }),
    iconActive: createIconWrapper(TableDocument, { variant: "Bold" }),
    subItems: [
        productSyncLogs,
        promotionSyncLogs,
        rewardSyncLogs,
        notificationSentLogs,
        registrationAndVerificationLogs,
    ],
};

export const adminRoutes: RouteItem[] = [
    overview,
    organizations,
    organizationsView,
    organizationsFeature,
    permissions,
    users,
    usersView,
    rolesList,
    settings,
    logs,
];
