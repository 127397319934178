import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getPunchcards as fetchPunchcards,
    getPunchcard as fetchPunchcard
} from "../../services/punchcard/punchcard.service";
import {
    getPunchcard,
    getPunchcardFailure,
    getPunchcardSuccess,
    getPunchcards,
    getPunchcardsFailure,
    getPunchcardsSuccess
} from "../reducer/punchcards";
import { GetPunchcardActionPayload, GetPunchcardsActionPayload } from "../types/punchcards";

function* getPunchcardsSaga(actions: GetPunchcardsActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchPunchcards,
            actions.payload
        );
        yield put(getPunchcardsSuccess(data));
    } catch (error: any) {
        yield put(getPunchcardsFailure(error));
    }
}

function* getPunchcardSaga(actions: GetPunchcardActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchPunchcard,
            actions.payload
        );
        yield put(getPunchcardSuccess(data));
    } catch (error: any) {
        yield put(getPunchcardFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getPunchcards.type, getPunchcardsSaga);
    yield takeLatest(getPunchcard.type, getPunchcardSaga);
}
