import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./core/context/auth";
import { themeChange } from "theme-change";
import { RightSideBarProvider } from "./core/context/rightSideBar";
import DispensaryRoutes from "./modules/routes";
import { Provider } from "react-redux";
import store from "./core/state/store";
import { ThemeContextProvider } from "./core/context/theme";

const App = () => {
    useEffect(() => {
        themeChange(false);
    }, []);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <AuthProvider>
                    <ThemeContextProvider>
                        <RightSideBarProvider>
                            <DispensaryRoutes />
                        </RightSideBarProvider>
                    </ThemeContextProvider>
                </AuthProvider>
            </BrowserRouter>
            <ToastContainer />
        </Provider>
    );
};

export default App;
