import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import {
    GetOrganizationAction,
    GetOrganizationActionPayload,
    OrganizationState,
} from "../types/organization";

const initialState: OrganizationState = {
    getOrganization: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        getOrganization(state) {
            state.getOrganization = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getOrganizationSuccess(state, actions) {
            state.getOrganization = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getOrganizationFailure(state) {
            state.getOrganization = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
    },
});

export const {
    getOrganization,
    getOrganizationSuccess,
    getOrganizationFailure,
} = organizationSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators({ getOrganization }, useDispatch());
};

export default organizationSlice.reducer;
