import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { BellIcon } from "@heroicons/react/24/solid";
import { useContext, useMemo, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth";
import {
    RightSidebarActions,
    RightSideBarContext,
} from "../../context/rightSideBar";
import defaultUserImg from "../../../../../assets/img/default-user.png";
import classNames from "classnames";
import { handleLogout } from "../../services/utils/utils.service";
import { canUserAccess } from "../../helpers/permission";
import { ROUTES } from "../../constants/routes";
import { useThemeContext } from "../../context/theme";
import CustomSkeleton from "../CustomSkeleton";

import { Moon, Sun1 } from "iconsax-react";

import LogoLight from "../../../../../assets/img/dm-logo-light.png";
import LogoDark from "../../../../../assets/img/dm-logo-dark.png";

const EditProfileForm = lazy(
    () => import("../../../modules/shared/auth/EditProfileForm")
);
const UpdatePasswordForm = lazy(
    () => import("../../../modules/shared/auth/UpdatePasswordForm")
);

const Header: React.FC = () => {
    const { authState } = useContext(AuthContext);
    const { dispatch: setRightSideBarState } = useContext(RightSideBarContext);
    const avatar = authState?.user?.attributes?.avatar || defaultUserImg;
    const { theme, updateTheme } = useThemeContext();

    const loading = useMemo(() => {
        return authState.loading;
    }, [authState.loading]);

    const resetPasswordAction = () => {
        setRightSideBarState({
            action: RightSidebarActions.SETITEM,
            component: authState.user && (
                <Suspense>
                    <UpdatePasswordForm
                        id={authState.user?.id}
                        token={authState.token.access_token}
                        mode="edit"
                    />
                </Suspense>
            ),
        });
    };

    const yourProfileAction = () => {
        setRightSideBarState({
            action: RightSidebarActions.SETITEM,
            component: authState.user && (
                <Suspense>
                    <EditProfileForm id={authState.user.id} mode="edit" />
                </Suspense>
            ),
        });
    };

    const primaryNavigation = [
        {
            htmlFor: "rightSideDrawer",
            name: "Your Profile",
            action: yourProfileAction,
        },
        {
            htmlFor: "rightSideDrawer",
            name: "Reset Password",
            action: resetPasswordAction,
        },
    ];

    const organizationAdminNavigation = [
        ...primaryNavigation,
        { name: "Sign out", action: handleLogout },
    ];

    const adminNavigation = [
        ...primaryNavigation,
        { name: "Sign out", action: handleLogout },
    ];

    const navigation = useMemo(() => {
        if (!authState.loading && authState && authState.user) {
            const type = authState.user.relationships.role.attributes.type;

            if (type === "admin") {
                return adminNavigation;
            } else {
                if (
                    canUserAccess(
                        "Manage Organization Users",
                        authState?.user?.relationships?.role?.relationships
                            .permissions
                    )
                ) {
                    return [
                        ...primaryNavigation,
                        {
                            name: "Organization",
                            href: ROUTES.USER.organization_management.key,
                        },
                        { name: "Sign out", action: handleLogout },
                    ];
                }
                return organizationAdminNavigation;
            }
        }
    }, [authState.user]);

    const ThemeToggle = () => {
        return (
            <label
                className="swap swap-rotate mr-4"
                title="Toggle theme"
                aria-label="Toggle theme"
            >
                <input
                    type="checkbox"
                    checked={theme === "dark"}
                    onChange={(e) => updateTheme(e.currentTarget.checked)}
                />
                <Moon className="swap-on h-6 w-6" />
                <Sun1 className="swap-off h-6 w-6" />
            </label>
        );
    };

    const NotificationCountBadge = () => {
        const notifCount = 0;
        const rootClass = classNames(
            "h-4 w-4 absolute  rounded-full text-white text-xs bg-red-500 float-left text-center inset-y-0 right-0",
            {
                hidden: notifCount === 0,
            }
        );
        return <div className={rootClass}>{notifCount}</div>;
    };

    const LeftProfileLinks = () => {
        return (
            <div className="flex items-center">
                {loading && (
                    <>
                        <CustomSkeleton
                            className="bg-badge-light-default rounded-full p-1 h-8 w-8  mr-4 "
                            circle
                        />
                        <CustomSkeleton
                            className="bg-badge-light-default rounded-full p-1 h-8 w-8"
                            circle
                        />
                    </>
                )}
                {!loading && (
                    <>
                        <ThemeToggle />
                        <Link to={"#"} className="p-1 rounded-full">
                            <div className="relative">
                                <NotificationCountBadge />

                                <div
                                    className={
                                        "hover:bg-slate-200 bg-badge-light-default rounded-full p-1 dark:bg-transparent dark:border-2 dark:border-neutral-500"
                                    }
                                >
                                    <BellIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </Link>
                    </>
                )}

                <div className="border-grey-200 border-r-2 h-8 ml-4" />
            </div>
        );
    };

    return (
        <div className="flex flex-col flex-1">
            <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 shadow-md bg-base-100">
                <div className="py-2 px-8 w-72 flex items-center">
                    <Link to="/">
                        {theme === "dark" ? (
                            authState?.user?.relationships?.organization
                                ?.attributes.dark_logo ? (
                                <img
                                    src={
                                        authState.user.relationships
                                            .organization.attributes.dark_logo
                                    }
                                    alt="logo"
                                    className="h-10 object-contain"
                                />
                            ) : (
                                <img
                                    src={LogoDark}
                                    alt="logo"
                                    className="h-10 object-contain"
                                />
                            )
                        ) : authState?.user?.relationships?.organization
                              ?.attributes.light_logo ? (
                            <img
                                src={
                                    authState.user.relationships.organization
                                        .attributes.light_logo
                                }
                                alt="logo"
                                className="h-10 object-contain"
                            />
                        ) : (
                            <img
                                src={LogoLight}
                                alt="logo"
                                className="h-10 object-contain"
                            />
                        )}
                    </Link>
                </div>
                <div className="self-center">
                    <label
                        htmlFor="leftSideDrawer"
                        className="px-4 border-r lg:hidden btn btn-link"
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3BottomLeftIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                        />
                    </label>
                </div>
                <div className="flex-1 px-4 flex justify-end">
                    <div className="ml-4 flex items-center md:ml-6">
                        <LeftProfileLinks />
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} className="btn btn-link">
                                <span className="sr-only">Open user menu</span>
                                <div className="h-full w-12">
                                    {loading && (
                                        <CustomSkeleton
                                            className="border-bg-badge-light-default p-[2px] h-full w-full object-cover object-top rounded-full"
                                            circle
                                        />
                                    )}
                                    {!loading && (
                                        <img
                                            className="border border-bg-badge-light-default p-[2px] h-full w-full object-cover object-top rounded-full"
                                            src={avatar}
                                            alt=""
                                        />
                                    )}
                                </div>
                            </div>
                            <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                            >
                                {navigation?.map((nav: any, index) => {
                                    return (
                                        <li
                                            key={`nav-${index}`}
                                            className="text-sm"
                                        >
                                            {nav?.action ? (
                                                <label
                                                    htmlFor={nav?.htmlFor}
                                                    onClick={nav?.action}
                                                >
                                                    {nav?.name}
                                                </label>
                                            ) : (
                                                <Link to={nav?.href}>
                                                    {nav?.name}
                                                </Link>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
