import { lazy } from "react";
import { RouteItem } from "../../interfaces/routes.interface";
import { ROUTES } from "../../constants/routes";

// Lazy load components
const Product = lazy(() => import("../../../modules/shared/product"));
const Checkout = lazy(() => import("../../../modules/shared/checkout"));
const Cart = lazy(() => import("../../../modules/shared/cart"));
const ThankYou = lazy(() => import("../../../modules/shared/checkout/ThankYou"));
const GameStats = lazy(() => import("../../../modules/shared/game/GameStats"));

const product: RouteItem = {
    id: ROUTES.PRODUCT.product.key,
    path: ROUTES.PRODUCT.product.key,
    component: Product,
};

const checkout: RouteItem = {
    id: ROUTES.PRODUCT.checkout.key,
    path: ROUTES.PRODUCT.checkout.key,
    component: Checkout,
};

const cart: RouteItem = {
    id: ROUTES.PRODUCT.cart.key,
    path: ROUTES.PRODUCT.cart.key,
    component: Cart,
};

const thankyou: RouteItem = {
    id: ROUTES.PRODUCT.thankyou.key,
    path: ROUTES.PRODUCT.thankyou.key,
    component: ThankYou,
};

const gameStats: RouteItem = {
    id: ROUTES.PRODUCT.game_stats.key,
    path: ROUTES.PRODUCT.game_stats.key,
    component: GameStats,
};

export const productRoutes: RouteItem[] = [
    product,
    checkout,
    thankyou,
    gameStats,
    cart
];
