export type RouteItem = {
    name?: string;
    id: string;
    path: string;
    component?: ({}: any) => any;
    guard?: any;
    role?: any;
    index?: boolean;
    userTypes?: UserTypes[];
    roles?: UserRoles[];
    icon?: React.ComponentType<any>;
    iconActive?: React.ComponentType<any>;
    hidden?: boolean;
    current?: boolean;
    isSecondaryItem?: boolean;
    props?: any;
    subItems?: RouteItem[];
};

export type ResolvePath = {
    resolve: (...props: any) => any;
};

export enum UserTypes {
    admin = "admin",
    user = "user",
}

export enum UserRoles {
    admin = "Admin",
    user = "User",
    staff = "Staff",
}
