import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getIntegration as fetchIntegration,
    getIntegrations as fetchIntegrations,
    getIntegrationTemplates as fetchIntegrationTemplates,
} from "../../services/integration/integration.service";
import {
    getIntegrations,
    getIntegrationsSuccess,
    getIntegrationsFailure,
    getIntegration,
    getIntegrationSuccess,
    getIntegrationFailure,
    getIntegrationTemplates,
    getIntegrationTemplatesSuccess,
    getIntegrationTemplatesFailure,
} from "../reducer/integrations";
import { GetIntegrationActionPayload } from "../types/integrations";

function* getIntegrationsSaga() {
    try {
        const { data }: AxiosResponse = yield call(fetchIntegrations);
        yield put(getIntegrationsSuccess(data));
    } catch (error: any) {
        yield put(getIntegrationsFailure(error));
    }
}

function* getIntegrationSaga(actions: GetIntegrationActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchIntegration,
            actions.payload
        );
        yield put(getIntegrationSuccess(data));
    } catch (error: any) {
        yield put(getIntegrationFailure(error));
    }
}

function* getIntegrationTemplatesSaga() {
    try {
        const { data }: AxiosResponse = yield call(fetchIntegrationTemplates);
        yield put(getIntegrationTemplatesSuccess(data));
    } catch (error: any) {
        yield put(getIntegrationTemplatesFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getIntegrations.type, getIntegrationsSaga);
    yield takeLatest(getIntegration.type, getIntegrationSaga);
    yield takeLatest(getIntegrationTemplates.type, getIntegrationTemplatesSaga);
}
