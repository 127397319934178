import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getOrganizations,
    showOrganization,
} from "../../services/organization/organization.service";
import {
    getAdminOrganizations,
    getAdminOrganizationsSuccess,
    getAdminOrganizationsFailure,
    getAdminOrganization,
    getAdminOrganizationFailure,
    getAdminOrganizationSuccess,
} from "../reducer/admin-organization";
import {
    GetAdminOrganizationsActionPayload,
    GetAdminOrganizationActionPayload,
} from "../types/admin-organization";

function* getOrganizationsSaga(actions: GetAdminOrganizationsActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            getOrganizations,
            actions.payload
        );
        yield put(getAdminOrganizationsSuccess(data));
    } catch (error: any) {
        yield put(getAdminOrganizationsFailure(error));
    }
}

function* getOrganizationSaga(actions: GetAdminOrganizationActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            showOrganization,
            actions.payload
        );
        yield put(getAdminOrganizationSuccess(data));
    } catch (error: any) {
        yield put(getAdminOrganizationFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getAdminOrganizations.type, getOrganizationsSaga);
    yield takeLatest(getAdminOrganization.type, getOrganizationSaga);
}
