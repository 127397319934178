import {createSlice} from "@reduxjs/toolkit";
import {bindActionCreators} from "redux";
import {useDispatch} from "react-redux";
import {RewardsState} from "../types/rewards";

const initialState: RewardsState = {
    getReward: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getRewards: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
};

const rewardsSlice = createSlice({
    name: "rewards",
    initialState,
    reducers: {
        getRewards(state, actions) {
            state.getRewards = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };
        },
        getRewardsSuccess(state, actions) {
            state.getRewards = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getRewardsFailure(state) {
            state.getRewards = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getReward(state) {
            state.getReward = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getRewardSuccess(state, actions) {
            state.getReward = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getRewardFailure(state) {
            state.getReward = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetReward(state) {
            state.getReward = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        removeRewardImage(state) {
            if (state.getReward?.data)
                state.getReward.data.attributes.image = "";
        },
    },
});

export const {
    getReward,
    getRewardFailure,
    getRewardSuccess,
    getRewards,
    getRewardsFailure,
    getRewardsSuccess,
    resetGetReward,
    removeRewardImage,
} = rewardsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getRewards,
            getReward,
            resetGetReward,
            removeRewardImage,
        },
        useDispatch()
    );
};

export default rewardsSlice.reducer;
