import { FC, SVGProps, Ref } from "react";
import { Icon, IconProps } from "iconsax-react";

const createIconWrapper = (
    IconsaxIcon: Icon,
    iconProps: Partial<IconProps> = {}
): FC<SVGProps<SVGSVGElement>> => {
    return ({ ref, ...props }) => (
        <IconsaxIcon {...props} variant="Linear" size={24} {...iconProps} />
    );
};

export default createIconWrapper;
