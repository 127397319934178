import { ROUTES } from "../../constants/routes";
import { ITenantFeatureAttributes } from "../../interfaces/tenant-feature.interface";
import { adminRoutes } from "./admin-routes";

import { budtenderRoutes, userRoutes } from "./user-routes";
export const getUserNavigation = (type: string, roleName: string, features?: ITenantFeatureAttributes) => {
    const adminSideBarPrimary = adminRoutes?.filter(
        (val) => !val.hidden && !val.isSecondaryItem
    );

    const adminSideBarSecondary = adminRoutes?.filter(
        (val) => !val.hidden && val.isSecondaryItem
    );

    const userSideBarItemsPrimary = userRoutes?.filter(
        (val) => {
            if (!features?.is_coupons_enabled && val.id === ROUTES.USER.coupons.key) return false;
            if (!features?.is_orders_enabled && val.id === ROUTES.USER.orders.key) return false;
            if (!features?.is_rewards_enabled && val.id === ROUTES.USER.rewards.key) return false;
            if (!features?.is_achievements_enabled && val.id === ROUTES.USER.achievements.key) return false;
            if (!features?.is_product_reviews_enabled && val.id === ROUTES.USER.product_reviews.key) return false;
            if (!features?.is_punchcards_enabled && val.id === ROUTES.USER.punchcards.key) return false;
            if (!features?.is_games_enabled && val.id === ROUTES.USER.games.key) return false;
            if (!features?.is_geofencing_enabled && val.id === ROUTES.USER.geofences.key) return false;
            if (!features?.is_daily_rewards_enabled && val.id === ROUTES.USER.daily_rewards.key) return false;
            if (!features?.is_referrals_enabled && [ROUTES.USER.reports.key, ROUTES.USER.referral_report.key].includes(val.id)) return false;
            return !val.hidden && !val.isSecondaryItem
        }
    );

    const userSideBarItemsSecondary = userRoutes?.filter(
        (val) => !val.hidden && val.isSecondaryItem
    );

    const budtenderSideBarItemsPrimary = budtenderRoutes?.filter(
        (val) => !val.hidden && !val.isSecondaryItem
    );

    if (roleName === 'Budtender') {
        return {
            primary: budtenderSideBarItemsPrimary,
            secondary: [],
        }
    }

    if (type === "admin") {
        return {
            primary: adminSideBarPrimary,
            secondary: adminSideBarSecondary,
        };
    } else {
        return {
            primary: userSideBarItemsPrimary,
            secondary: userSideBarItemsSecondary,
        };
    }
};
