import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import {
    OrganizationStaffState,
    GetOrganizationStaffActionPayload,
} from "../types/organization-staff";

const initialState: OrganizationStaffState = {
    getOrganizationStaff: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getOrganizationInvitedStaff: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const organizationStaffSlice = createSlice({
    name: "organizationStaff",
    initialState,
    reducers: {
        getOrganizationStaff(state) {
            state.getOrganizationStaff = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getOrganizationStaffSuccess(state, actions) {
            state.getOrganizationStaff = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getOrganizationStaffFailure(state) {
            state.getOrganizationStaff = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        // invted staff

        getOrganizationInvitedStaff(state) {
            state.getOrganizationInvitedStaff = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getOrganizationInvitedStaffSuccess(state, actions) {
            state.getOrganizationInvitedStaff = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getOrganizationInvitedStaffFailure(state) {
            state.getOrganizationInvitedStaff = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
    },
});

export const {
    getOrganizationStaff,
    getOrganizationStaffSuccess,
    getOrganizationStaffFailure,
    getOrganizationInvitedStaff,
    getOrganizationInvitedStaffSuccess,
    getOrganizationInvitedStaffFailure
} = organizationStaffSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators({ getOrganizationStaff, getOrganizationInvitedStaff }, useDispatch());
};

export default organizationStaffSlice.reducer;
