import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getPromotions as fetchPromotions,
    getPromotion as fetchPromotion,
} from "../../services/promotion/promotion.service";
import {
    getPromotion,
    getPromotionFailure,
    getPromotionSuccess,
    getPromotions,
    getPromotionsFailure,
    getPromotionsSuccess,
} from "../reducer/promotions";
import { GetPromotionActionPayload, GetPromotionsActionPayload } from "../types/promotions";

function* getPromotionsSaga(actions: GetPromotionsActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchPromotions,
            actions.payload
        );
        yield put(getPromotionsSuccess(data));
    } catch (error: any) {
        yield put(getPromotionsFailure(error));
    }
}

function* getPromotionSaga(actions: GetPromotionActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchPromotion,
            actions.payload
        );
        yield put(getPromotionSuccess(data));
    } catch (error: any) {
        yield put(getPromotionFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getPromotions.type, getPromotionsSaga);
    yield takeLatest(getPromotion.type, getPromotionSaga);
}
