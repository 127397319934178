import { lazy } from "react";
import { RouteItem } from "../../../core/interfaces/routes.interface";
import { ROUTES } from "../../../core/constants/routes";

// Lazy loading components
const ForgotPassword = lazy(() => import("../../../modules/shared/auth/ForgotPassword"));
const Login = lazy(() => import("../../../modules/shared/auth/Login"));
const Register = lazy(() => import("../../../modules/shared/auth/Register"));
const RegisterWithOTP = lazy(() => import("../../../modules/shared/auth/RegisterWithOTP"));
const ResetPassword = lazy(() => import("../../../modules/shared/auth/ResetPassword"));
const TwoStepVerification = lazy(() => import("../../../modules/shared/auth/TwoStepVerification"));

const login: RouteItem = {
    id: ROUTES.AUTH.login.key,
    path: ROUTES.AUTH.login.key,
    component: Login,
    index: true,
};

const register: RouteItem = {
    id: ROUTES.AUTH.register.key,
    path: ROUTES.AUTH.register.key,
    component: Register,
};

const registerWithOtp: RouteItem = {
    id: ROUTES.AUTH.register_with_otp.key,
    path: ROUTES.AUTH.register_with_otp.key,
    component: RegisterWithOTP,
};

const twoFactorVerification: RouteItem = {
    id: ROUTES.AUTH.two_factor_verification.key,
    path: ROUTES.AUTH.two_factor_verification.key,
    component: TwoStepVerification,
};

const forgotPassword: RouteItem = {
    id: ROUTES.AUTH.forgot_password.key,
    path: ROUTES.AUTH.forgot_password.key,
    component: ForgotPassword,
};

const resetPassword: RouteItem = {
    id: ROUTES.AUTH.reset_password.key,
    path: ROUTES.AUTH.reset_password.key,
    component: ResetPassword,
};

export const authRoutes: RouteItem[] = [
    login,
    register,
    registerWithOtp,
    twoFactorVerification,
    forgotPassword,
    resetPassword,
];
