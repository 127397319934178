import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { getInvitedStaff, getStaff } from "../../services/staff/staff.service";
import {
    getOrganizationStaff,
    getOrganizationStaffSuccess,
    getOrganizationStaffFailure,
    getOrganizationInvitedStaff,
    getOrganizationInvitedStaffSuccess,
    getOrganizationInvitedStaffFailure,
} from "../reducer/organization-staff";
import { GetAdminOrganizationActionPayload } from "../types/admin-organization";

function* getOrganizationStaffSaga(actions?: GetAdminOrganizationActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(getStaff, actions?.payload);
        yield put(getOrganizationStaffSuccess(data));
    } catch (error: any) {
        yield put(getOrganizationStaffFailure(error));
    }
}

function* getOrganizationInvitedStaffSaga(actions?: GetAdminOrganizationActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(getInvitedStaff);
        yield put(getOrganizationInvitedStaffSuccess(data));
    } catch (error: any) {
        yield put(getOrganizationInvitedStaffFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getOrganizationStaff.type, getOrganizationStaffSaga);
    yield takeLatest(getOrganizationInvitedStaff.type, getOrganizationInvitedStaffSaga);



}
