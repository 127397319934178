import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { GeofenceState } from "../types/geofences";

const initialState: GeofenceState = {
    getGeofences: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getGeofence: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const geofencesSlice = createSlice({
    name: "geofences",
    initialState,
    reducers: {
        getGeofences(state) {
            state.getGeofences = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getGeofencesSuccess(state, actions) {
            state.getGeofences = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getGeofencesFailure(state) {
            state.getGeofences = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getGeofence(state) {
            state.getGeofence = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getGeofenceSuccess(state, actions) {
            state.getGeofence = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getGeofenceFailure(state) {
            state.getGeofence = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetGeofence(state) {
            state.getGeofence = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
    },
});

export const {
    getGeofence,
    getGeofenceSuccess,
    getGeofenceFailure,
    getGeofences,
    getGeofencesFailure,
    getGeofencesSuccess,
    resetGetGeofence,
} = geofencesSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        { getGeofence, getGeofences, resetGetGeofence },
        useDispatch()
    );
};

export default geofencesSlice.reducer;
