import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getCustomers as fetchCustomers
} from "../../services/customer/customer.service";
import {
    getCustomers,
    getCustomersFailure,
    getCustomersSuccess
} from "../reducer/customers";
import { GetCustomersActionPayload } from "../types/customers";

function* getCustomersSaga(actions: GetCustomersActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchCustomers,
            actions.payload
        );
        yield put(getCustomersSuccess(data));
    } catch (error: any) {
        yield put(getCustomersFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getCustomers.type, getCustomersSaga);
}
