import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getRewards as fetchRewards,
    getReward as fetchReward,
} from "../../services/reward/reward.service";
import {
    getReward,
    getRewardFailure,
    getRewardSuccess,
    getRewards,
    getRewardsFailure,
    getRewardsSuccess,
} from "../reducer/rewards";
import { GetRewardActionPayload, GetRewardsActionPayload } from "../types/rewards";

function* getRewardsSaga(actions: GetRewardsActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchRewards,
            actions.payload
        );
        yield put(getRewardsSuccess(data));
    } catch (error: any) {
        yield put(getRewardsFailure(error));
    }
}

function* getRewardSaga(actions: GetRewardActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchReward,
            actions.payload
        );
        yield put(getRewardSuccess(data));
    } catch (error: any) {
        yield put(getRewardFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getRewards.type, getRewardsSaga);
    yield takeLatest(getReward.type, getRewardSaga);
}
