import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { ProductReviewsState } from "../types/product-reviews";

const initialState: ProductReviewsState = {
    getProductReview: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getProductReviews: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
};

const producReviewsSlice = createSlice({
    name: "productReviews",
    initialState,
    reducers: {
        getProductReviews(state, actions) {
            state.getProductReviews = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };
        },
        getProductReviewsSuccess(state, actions) {
            state.getProductReviews = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getProductReviewsFailure(state) {
            state.getProductReviews = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getProductReview(state) {
            state.getProductReview = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getProductReviewSuccess(state, actions) {
            state.getProductReview = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getProductReviewFailure(state) {
            state.getProductReview = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetProductReview(state) {
            state.getProductReview = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        removeProductReviewImage(state) {
            if (state.getProductReview?.data)
                state.getProductReview.data.attributes.image = "";
        },
    },
});

export const {
    getProductReviews,
    getProductReviewsSuccess,
    getProductReviewsFailure,
    getProductReview,
    getProductReviewFailure,
    getProductReviewSuccess,
    resetGetProductReview,
    removeProductReviewImage,
} = producReviewsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getProductReviews,
            getProductReview,
            resetGetProductReview,
            removeProductReviewImage,
        },
        useDispatch()
    );
};

export default producReviewsSlice.reducer;
