import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { PromotionsState } from "../types/promotions";

const initialState: PromotionsState = {
    getPromotion: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getPromotions: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
};

const promotionsSlice = createSlice({
    name: "promotions",
    initialState,
    reducers: {
        getPromotions(state, actions) {
            state.getPromotions = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };
        },
        getPromotionsSuccess(state, actions) {
            state.getPromotions = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getPromotionsFailure(state) {
            state.getPromotions = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },

        /**
         * GET STORE BY ID
         */

        getPromotion(state) {
            state.getPromotion = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getPromotionSuccess(state, actions) {
            state.getPromotion = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getPromotionFailure(state) {
            state.getPromotion = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetPromotion(state) {
            state.getPromotion = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
        removePromotionFullScreenBanner(state) {
            if (state.getPromotion?.data)
                state.getPromotion.data.attributes.full_screen_banner = "";
        },
        removePromotionHorizontalBanner(state) {
            if (state.getPromotion?.data)
                state.getPromotion.data.attributes.home_horizontal_banner = "";
        },
        removePromotionHomeTileBanner(state) {
            if (state.getPromotion?.data)
                state.getPromotion.data.attributes.home_tile_banner = "";
        },
    },
});

export const {
    getPromotion,
    getPromotionFailure,
    getPromotionSuccess,
    getPromotions,
    getPromotionsFailure,
    getPromotionsSuccess,
    resetGetPromotion,
    removePromotionFullScreenBanner,
    removePromotionHorizontalBanner,
    removePromotionHomeTileBanner,
} = promotionsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getPromotions,
            getPromotion,
            resetGetPromotion,
            removePromotionFullScreenBanner,
            removePromotionHorizontalBanner,
            removePromotionHomeTileBanner,
        },
        useDispatch()
    );
};

export default promotionsSlice.reducer;
