import httpClient from "../../clients/httpClient";
import {IGeofencePayload} from "../../interfaces/geofence.interface";

export const getGeofences = () => {
    return httpClient.get(`/api/v1/geofences`);
};

export const getGeofence = (id: string) => {
    return httpClient.get(`/api/v1/geofences/${id}`);
};

export const createGeofence = (payload: IGeofencePayload) => {
    return httpClient.post("/api/v1/geofences", payload);
};

export const editGeofence = (id: number, payload: IGeofencePayload) => {
    return httpClient.patch(`/api/v1/geofences/${id}`, payload);
};

export const deleteGeofence = (id: number) => {
    return httpClient.delete(`/api/v1/geofences/${id}`);
};
