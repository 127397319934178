import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { PermissionState } from "../types/permissions";

const initialState: PermissionState = {
    getPermissions: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        getPermissions(state) {
            state.getPermissions = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getPermissionsSuccess(state, actions) {
            state.getPermissions = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getPermissionsFailure(state) {
            state.getPermissions = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
    },
});

export const { getPermissions, getPermissionsSuccess, getPermissionsFailure } =
    permissionsSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators({ getPermissions }, useDispatch());
};

export default permissionsSlice.reducer;
