import httpClient from "../../clients/httpClient";
import { IQuery } from "../../interfaces/meta.interface";
import { IReward, IRewardResponse } from "../../interfaces/reward.interface";

export const getRewards = (query: IQuery) => {
    return httpClient.get("/api/v1/rewards", {
        params: {
            page: query?.page,
            per_page: query?.perPage,
            direction: query?.sortDirection,
            sort: query?.orderBy,
        },
    });
};

export const getReward = (id: number) => {
    return httpClient.get<IRewardResponse>(`/api/v1/rewards/${id}`, {
        params: {
            include: "products",
        }
    });
};

export const createReward = (payload: FormData) => {
    return httpClient.post<IReward>(`/api/v1/rewards`, payload);
};

export const editReward = (id: number, payload: FormData) => {
    return httpClient.post<IReward>(`/api/v1/rewards/${id}`, payload, {
        params: {
            include: "products",
        }
    });
};

export const getCheckoutCustomRewardsRequest = (storeId: string, token: string) => {
    return httpClient.get<IReward[]>(`/api/v1/store/${storeId}/rewards/${token}`);
};
