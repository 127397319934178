import httpClient from "../../clients/httpClient";
import {IIntegrationTemplatePayload} from "../../interfaces/integration-template.interface";

export const getOrganizationIntegrationTemplates = () => {
    return httpClient.get(`/api/v1/integration-templates`);
};

export const getIntegrationTemplates = () => {
    return httpClient.get(`/api/v1/admin/integration-templates`);
};

export const getIntegrationTemplate = (id: string) => {
    return httpClient.get(`/api/v1/admin/integration-templates/${id}`);
};

export const createIntegrationTemplate = (payload: IIntegrationTemplatePayload) => {
    return httpClient.post("/api/v1/admin/integration-templates", payload);
};

export const editIntegrationTemplate = (id: number, payload: IIntegrationTemplatePayload) => {
    return httpClient.post(`/api/v1/admin/integration-templates/${id}`, payload);
};

export const deleteIntegrationTemplate = (id: number) => {
    return httpClient.delete(`/api/v1/admin/integration-templates/${id}`);
};
