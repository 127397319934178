import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { CustomersState } from "../types/customers";

const initialState: CustomersState = {
    getCustomer: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getCustomers: {
        data: {
            meta: undefined,
            data: undefined,
        },
        loading: false,
        success: false,
        error: false,
    },
};

const customersSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        getCustomers(state, actions) {
            state.getCustomers = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: true,
                success: false,
                error: false,
            };
        },
        getCustomersSuccess(state, actions) {
            state.getCustomers = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getCustomersFailure(state) {
            state.getCustomers = {
                data: {
                    meta: undefined,
                    data: undefined,
                },
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetCustomer(state) {
            state.getCustomer = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
    },
});

export const {
    getCustomers,
    getCustomersFailure,
    getCustomersSuccess,
    resetGetCustomer,
} = customersSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getCustomers,
            resetGetCustomer,
        },
        useDispatch()
    );
};

export default customersSlice.reducer;
