import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import {
    getProductReviews as fetchProductReviews,
    getProductReview as fetchProductReview,
} from "../../services/product-review/product-review.service";
import {
    getProductReviews,
    getProductReviewsSuccess,
    getProductReviewsFailure,
    getProductReview,
    getProductReviewFailure,
    getProductReviewSuccess,
} from "../reducer/product-reviews";
import { GetProductReviewsPayload, GetProductReviewtActionPayload } from "../types/product-reviews";

function* getProductReviewsSaga(actions: GetProductReviewsPayload) {    
    try {
        const { data }: AxiosResponse = yield call(
            fetchProductReviews,
            actions.payload
        );
        yield put(getProductReviewsSuccess(data));
    } catch (error: any) {
        yield put(getProductReviewsFailure(error));
    }
}

function* getProductReviewSaga(actions: GetProductReviewtActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchProductReview,
            actions.payload
        );
        yield put(getProductReviewSuccess(data));
    } catch (error: any) {
        yield put(getProductReviewFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getProductReviews.type, getProductReviewsSaga);
    yield takeLatest(getProductReview.type, getProductReviewSaga);
}
