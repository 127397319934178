import { createSlice } from "@reduxjs/toolkit";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { IntegrationTemplatesState } from "../types/integration-templates";

const initialState: IntegrationTemplatesState = {
    getIntegrationTemplate: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
    getIntegrationTemplates: {
        data: undefined,
        loading: false,
        success: false,
        error: false,
    },
};

const integrationTemplatesSlice = createSlice({
    name: "integrationTemplates",
    initialState,
    reducers: {
        getIntegrationTemplates(state) {
            state.getIntegrationTemplates = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getIntegrationTemplatesSuccess(state, actions) {
            state.getIntegrationTemplates = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getIntegrationTemplatesFailure(state) {
            state.getIntegrationTemplates = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },

        getIntegrationTemplate(state) {
            state.getIntegrationTemplate = {
                data: undefined,
                loading: true,
                success: false,
                error: false,
            };
        },
        getIntegrationTemplateSuccess(state, actions) {
            state.getIntegrationTemplate = {
                data: actions.payload,
                loading: false,
                success: true,
                error: false,
            };
        },
        getIntegrationTemplateFailure(state) {
            state.getIntegrationTemplate = {
                data: undefined,
                loading: false,
                success: false,
                error: true,
            };
        },
        resetGetIntegrationTemplate(state) {
            state.getIntegrationTemplate = {
                data: undefined,
                loading: false,
                success: false,
                error: false,
            };
        },
    },
});

export const {
    getIntegrationTemplate,
    getIntegrationTemplateFailure,
    getIntegrationTemplateSuccess,
    getIntegrationTemplates,
    getIntegrationTemplatesFailure,
    getIntegrationTemplatesSuccess,
    resetGetIntegrationTemplate,
} = integrationTemplatesSlice.actions;

export const mapDispatchToProps = () => {
    return bindActionCreators(
        {
            getIntegrationTemplates,
            getIntegrationTemplate,
            resetGetIntegrationTemplate,
        },
        useDispatch()
    );
};

export default integrationTemplatesSlice.reducer;
