import { all, fork } from "redux-saga/effects";
import * as adminOrganizationSaga from "./admin-organization";
import * as organizationStaffSaga from "./organization-staff";
import * as permissionsSaga from "./permissions";
import * as adminUsersSaga from "./admin-users";
import * as rolesSaga from "./roles";
import * as storesSaga from "./stores";
import * as achievementsSaga from "./achievements";
import * as organizationAchievementsSaga from "./organization-achievements";
import * as productReviewsSaga from "./product-reviews";
import * as promotionSaga from "./promotions";
import * as settingsSaga from "./settings";
import * as organizationSaga from "./organization";
import * as punchcardSaga from "./punchcards";
import * as customersSaga from "./customers";
import * as rewardsSaga from "./rewards";
import * as integrationTemplatesSaga from "./integration-templates";
import * as integrationsSaga from "./integrations";
import * as geofencesSaga from "./geofences";

export default function* root() {
    const sagas = [
        ...Object.values(adminOrganizationSaga),
        ...Object.values(organizationStaffSaga),
        ...Object.values(permissionsSaga),
        ...Object.values(adminUsersSaga),
        ...Object.values(rolesSaga),
        ...Object.values(storesSaga),
        ...Object.values(achievementsSaga),
        ...Object.values(organizationAchievementsSaga),
        ...Object.values(productReviewsSaga),
        ...Object.values(promotionSaga),
        ...Object.values(settingsSaga),
        ...Object.values(organizationSaga),
        ...Object.values(punchcardSaga),
        ...Object.values(customersSaga),
        ...Object.values(rewardsSaga),
        ...Object.values(rewardsSaga),
        ...Object.values(integrationTemplatesSaga),
        ...Object.values(integrationsSaga),
        ...Object.values(geofencesSaga),
    ];
    yield all(sagas.map(fork));
}
