import { put, PutEffect } from "redux-saga/effects";
import { toast } from "react-toastify";

export const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
};

export function* handleServerException(
    error: any,
    actionType?: string
): Generator<PutEffect> {
    if (!error && actionType) {
        yield put({ type: actionType });
    } else {
        const errorStatus = error.response?.status;
        switch (errorStatus) {
            case 401:
            case 403:
                toast.error(
                    "User not authenticated to do the request, account manually logging out..."
                );
                handleLogout();
                break;
            default:
                if (actionType) {
                    yield put({ type: actionType });
                }
                break;
        }
    }
}

export function createAction(type: string, payload: any) {
    return {
        type,
        payload,
    };
}

export const ellipsesText = (text: string, limit = 100) => {
    if (text.length <= limit) return text;
    return text.slice(0, limit) + "...";
};
