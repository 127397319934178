import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { RoleTypes } from "../../enums/roles.enum";
import { getPermissionsByType } from "../../services/permission/permission.service";
import {
    getPermissions,
    getPermissionsSuccess,
    getPermissionsFailure,
} from "../reducer/permissions";

function* getOrganizationStaffSaga() {
    try {
        const { data: userPermissions }: AxiosResponse = yield call(
            getPermissionsByType,
            RoleTypes.admin
        );
        const { data: adminPermissions }: AxiosResponse = yield call(
            getPermissionsByType,
            RoleTypes.user
        );
        yield put(
            getPermissionsSuccess([...userPermissions, ...adminPermissions])
        );
    } catch (error: any) {
        yield put(getPermissionsFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getPermissions.type, getOrganizationStaffSaga);
}
