import ReactDOM from "react-dom/client";
import "./app/core/helpers/sentry";
import App from "./app";
import "./index.scss";

ReactDOM.createRoot(document.getElementById("root")!).render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
);
