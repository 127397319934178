import { AxiosResponse } from "axios";
import { takeLatest, call, put } from "redux-saga/effects";
import { getOrganizationData } from "../../services/organization-user/organization.service";
import {
    getOrganization,
    getOrganizationSuccess,
    getOrganizationFailure,
} from "../reducer/organization";

function* getOrganizationSaga() {
    try {
        const { data }: AxiosResponse = yield call(getOrganizationData);
        yield put(getOrganizationSuccess(data));
    } catch (error: any) {
        yield put(getOrganizationFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getOrganization.type, getOrganizationSaga);
}
