import {AxiosResponse} from "axios";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    getOrganizationAchievement as fetchOrganizationAchievement,
    getOrganizationAchievements as fetchOrganizationAchievements,
} from "../../services/organization-achievement/organization-achievement.service";

import {
    getOrganizationAchievement,
    getOrganizationAchievementFailure,
    getOrganizationAchievements,
    getOrganizationAchievementsFailure,
    getOrganizationAchievementsSuccess,
    getOrganizationAchievementSuccess
} from "../reducer/organization-achievements";
import { GetOrganizationAchievementActionPayload, GetOrganizationAchievementsPayload } from "../types/organization-achievements";

function* getOrganizationAchievementsSaga(actions: GetOrganizationAchievementsPayload) {
    try {
        const { data }: AxiosResponse = yield call(fetchOrganizationAchievements, actions.payload);
        yield put(getOrganizationAchievementsSuccess(data));
    } catch (error: any) {
        yield put(getOrganizationAchievementsFailure(error));
    }
}

function* getOrganizationAchievementSaga(actions: GetOrganizationAchievementActionPayload) {
    try {
        const { data }: AxiosResponse = yield call(
            fetchOrganizationAchievement,
            actions.payload
        );
        yield put(getOrganizationAchievementSuccess(data));
    } catch (error: any) {
        yield put(getOrganizationAchievementFailure(error));
    }
}

export function* rootSaga() {
    yield takeLatest(getOrganizationAchievements.type, getOrganizationAchievementsSaga);
    yield takeLatest(getOrganizationAchievement.type, getOrganizationAchievementSaga);
}
