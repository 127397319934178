import httpClient from "../../clients/httpClient";
import {IAchievementPayload} from "../../interfaces/achievement.interface";

export const getAchievements = () => {
    return httpClient.get(`/api/v1/achievements`);
};

export const getAchievement = (id: string) => {
    return httpClient.get(`/api/v1/achievements/${id}`);
};

export const createAchievement = (payload: IAchievementPayload) => {
    return httpClient.post("/api/v1/achievements", payload);
};

export const editAchievement = (id: number, payload: IAchievementPayload) => {
    return httpClient.post(`/api/v1/achievements/${id}`, payload);
};

export const deleteAchievement = (id: number) => {
    return httpClient.delete(`/api/v1/achievements/${id}`);
};
